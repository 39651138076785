/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "./src/styles/tailwind.css"
import { getLangAttribute } from "./src/constants/locale.constants"

const onRouteUpdate = ({ location }) => {
    const { pathname } = location
    document.documentElement.lang = getLangAttribute(pathname)
    if (pathname.includes("/index.html")) {
        const newPathname = pathname.replace("/index.html", "/")
        window.location.href = newPathname
    }
}

const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
    const currentPath = routerProps.location.pathname
    const prevPath = prevRouterProps?.location.pathname
    if (currentPath === prevPath) {
        return false
    }

    return true
}

export { onRouteUpdate, shouldUpdateScroll }
