const LOCALE_NL_BE = "nl-BE"
const LOCALE_FR_BE = "fr-BE"
const LOCALE_NL_NL = "nl-NL"

const getLangAttribute = pathname => {
    let locale = "nl"
    if (pathname.startsWith(`/${getUrlLocale(LOCALE_NL_BE)}`)) {
        locale = "nl"
    } else if (pathname.startsWith(`/${getUrlLocale(LOCALE_FR_BE)}`)) {
        locale = "fr"
    } else if (pathname.startsWith(`/${getUrlLocale(LOCALE_NL_NL)}`)) {
        locale = "nl"
    }
    return locale
}

const getRecipeUrl = recipe => {
    switch (recipe.locale) {
        case LOCALE_NL_BE:
            return `/${getUrlLocale(LOCALE_NL_BE)}/recepten/${recipe["seo_page_name"]}/`
        case LOCALE_FR_BE:
            return `/${getUrlLocale(LOCALE_FR_BE)}/recettes/${recipe["seo_page_name"]}/`
        case LOCALE_NL_NL:
            return `/${getUrlLocale(LOCALE_NL_NL)}/recepten/${recipe["seo_page_name"]}/`
        default:
            return undefined
    }
}

const getProductUrl = product => {
    switch (product.locale) {
        case LOCALE_NL_BE:
            return `/${getUrlLocale(LOCALE_NL_BE)}/producten/${product["seo_page_name"]}/`
        case LOCALE_FR_BE:
            return `/${getUrlLocale(LOCALE_FR_BE)}/produits/${product["seo_page_name"]}/`
        case LOCALE_NL_NL:
            return `/${getUrlLocale(LOCALE_NL_NL)}/producten/${product["seo_page_name"]}/`
        default:
            return undefined
    }
}

const getUrlLocale = locale => {
    switch (locale) {
        case LOCALE_NL_BE:
            return "nl-be"
        case LOCALE_FR_BE:
            return "fr-be"
        case LOCALE_NL_NL:
            return "nl"
        default:
            return null
    }
}
const localeErrorPageMatches = {
    [LOCALE_NL_BE]: `/${getUrlLocale(LOCALE_NL_BE)}/*`,
    [LOCALE_FR_BE]: `/${getUrlLocale(LOCALE_FR_BE)}/*`,
    [LOCALE_NL_NL]: `/${getUrlLocale(LOCALE_NL_NL)}/*`,
}

module.exports = {
    LOCALE_NL_BE,
    LOCALE_FR_BE,
    LOCALE_NL_NL,
    getLangAttribute,
    getRecipeUrl,
    getUrlLocale,
    getProductUrl,
    localeErrorPageMatches,
}
